import React from 'react';
import { Container, Navbar, Nav, Form } from 'react-bootstrap';
import { useTheme } from '../../context/ThemeContext';
import './Navigation.css';

export const Navigation: React.FC = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <Navbar bg={theme === 'dark' ? 'dark' : 'light'} variant={theme === 'dark' ? 'dark' : 'light'} expand='lg'>
      <Container>
        <Navbar.Brand href='/'>Puffy Pastures</Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link href='/albums'>Photo Albums</Nav.Link>
            <Nav.Link href='/information'>Information</Nav.Link>
          </Nav>
          <Form.Switch id='theme-toggle' label={theme === 'dark' ? '🌙 Dark' : '☀️ Light'} checked={theme === 'dark'} onChange={toggleTheme} className='me-2' />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
