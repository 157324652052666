import React from 'react';
import { Carousel } from 'react-bootstrap';
import './Home.css';

export const Home: React.FC = () => {
  // Placeholder images, replace with dynamic loading logic
  const images = [
    { id: 1, src: '/pexels-ellie-burgin-11269493.jpg', alt: '' },
    { id: 2, src: '/pexels-moepoofles-moepoofles-3635899.jpg', alt: '' },
    { id: 3, src: '/pexels-roman-odintsov-6897497.jpg', alt: '' },
    { id: 4, src: '/pexels-vladimir-uporov-8864469.jpg', alt: '' },
    { id: 5, src: '/pexels-yves-chaput-1128574.jpg', alt: '' },
  ];

  return (
    <Carousel>
      {images.map(image => (
        <Carousel.Item key={image.id}>
          <img className='d-block w-100 img-fluid' src={image.src} alt={image.alt} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
