import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export const Information: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1>About Puffy Pastures</h1>
          <p>
            Welcome to Puffy Pastures, a serene escape into the countryside. Here at Puffy Pastures, we cherish the simplicity and beauty of farm life. Our farm offers a variety of experiences from
            photo-worthy landscapes to hands-on agricultural activities.
          </p>
          <p>Contact us for more information or to plan your visit.</p>
          <p>info@puffypastures.com</p>
        </Col>
      </Row>
    </Container>
  );
};
