import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigation } from './components/navigation/Navigation';
import { Footer } from './components/footer/Footer';
import { Home } from './pages/Home';
import { Information } from './pages/Information';
import { PhotoAlbums } from './pages/PhotoAlbums';

export const App: React.FC = () => {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/albums' element={<PhotoAlbums />} />
        <Route path='/information' element={<Information />} />
      </Routes>
      <Footer />
    </Router>
  );
};
