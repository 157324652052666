import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

export const PhotoAlbums: React.FC = () => {
  // Placeholder data, replace with dynamic data from your backend
  const albums = [
    { id: 1, src: 'https://via.placeholder.com/200x200?text=Album+1', title: 'Album 1' },
    { id: 2, src: 'https://via.placeholder.com/200x200?text=Album+2', title: 'Album 2' },
    { id: 3, src: 'https://via.placeholder.com/200x200?text=Album+3', title: 'Album 3' },
  ];

  return (
    <Container>
      <Row>
        {albums.map(album => (
          <Col key={album.id} sm={12} md={6} lg={4} className='mb-4'>
            <Card>
              <Card.Img variant='top' src={album.src} />
              <Card.Body>
                <Card.Title>{album.title}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
