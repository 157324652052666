import React from 'react';
import { Container } from 'react-bootstrap';
import { useTheme } from '../../context/ThemeContext';

export const Footer: React.FC = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <footer className='mt-auto py-3'>
      <Container>
        <span className={theme === 'dark' ? 'text-light' : 'text-muted'}>Puffy Pastures © {new Date().getFullYear()}</span>
      </Container>
    </footer>
  );
};
